import { ref, Ref, computed } from 'vue'
import moment from 'moment'
import Papa from 'papaparse'
import Swal from 'sweetalert2'
import { axiosInstance as axios } from '@/plugins/axios.js'
import { downloadFile, formatApiCallDate } from '../utils'

const ledgerAccountId = ref()
const wallets = ref([])
const data_limit = 10
const walletIndex = ref(0)
const currentWallet = computed(() =>
{
  if (wallets.value.length) return wallets.value[walletIndex.value]
})

export const useWallets = () =>
{
  const loading = ref(false)
  const fetchWallets = (company_id) =>
  {
    loading.value = true
    axios
      .get(`/v1/corporates/${company_id}/wallets`)
      .then((res) =>
      {
        loading.value = false
        res.data.data.length
          ? (wallets.value = res.data.data)
          : (wallets.value = [])
      })
      .catch((err) =>
      {
        loading.value = false
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err?.response?.data?.message ?? 'An error occured',
        })
      })
  }

  return {
    loading,
    fetchWallets,
    wallets,
    ledgerAccountId,
    walletIndex,
    currentWallet,
  }
}

export const useActivateWallet = () =>
{
  const loading = ref(false)
  const bvn = ref('')
  const provider = ref('FlutterwaveNubanProvider')
  const activateWallet = () =>
  {
    loading.value = true
    axios
      .post(`/v1/ledger-accounts/${ledgerAccountId.value}/nuban-addresses`, {
        bvn: bvn.value,
        provider: provider.value,
      })
      .then((res) =>
      {
        loading.value = false
        if (res.data.nubanAddress) {
          Swal.fire({
            icon: 'success',
            title: 'Account Activated',
            text: 'Your account is ready for you to start making transactions',
          })
          useModal().closeModal()
          useWallets().fetchWallets()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message ?? 'Unable to activate wallet',
          })
        }
      })
      .catch((err) =>
      {
        loading.value = false
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err?.response?.data?.message ?? 'An error occured',
        })
      })
  }

  return { loading, bvn, provider, activateWallet, ledgerAccountId }
}

const walletTransactions = ref([])
const walletMetaData = ref(null)
export const useWalletTransaction = () =>
{
  const loading = ref(false)
  const fetchTransactions = (
    walletId,
    type = 'all',
    start_date = null,
    end_date = null,
    userId = '',
    page = 1
  ) =>
  {
    loading.value = true
    axios
      .get(
        `/v1/corporate-wallets/${walletId}/transactions`,
        {
          params: {
            'metadata': true,
            'page': page,
            ...(userId && { 'filters[user_ids]': userId }),
            'limit': data_limit,
            ...(type && { 'filters[type]': type }),
            'filters[start_date]': start_date ? formatApiCallDate(start_date) : undefined,
            'filters[end_date]': end_date ? formatApiCallDate(end_date) : undefined
          }
        }
      )
      .then((res) =>
      {
        loading.value = false
        if (res.data.data.length) {
          walletTransactions.value = res.data.data
          walletMetaData.value = res.data.metadata
        } else {
          walletTransactions.value = []
        }
      })
      .catch((err) =>
      {
        loading.value = false
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err?.response?.data?.message ?? 'An error occured',
        })
      })
  }

  return { loading, fetchTransactions, walletTransactions, walletMetaData }
}

export const useFlutterWave = () =>
{
  const amount = ref('')
  const desc = ref('')
  const loading = ref(false)
  const makePayment = async (company_id) =>
  {
    loading.value = true
    // makeFetchWithAuthAndBody('POST', `v1/ledger-accounts/${currentWallet.value?.wallet.ledger_account_id}/funding-references`, {
    // 	provider: 'flutterwave',
    // 	reference: `shuttlers_${new Date().getTime()}`
    // })
    const resp = await axios.get(`/v1/corporates/${company_id}`)
    if (!resp?.data) {
      loading.value = false
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Could not get company details',
      })
      return
    }
    const company = resp.data
    axios
      .post(
        `/v1/ledger-accounts/${currentWallet.value?.wallet.ledger_account_id}/funding-references`,
        {
          provider: 'flutterwave',
          reference: `shuttlers_${new Date().getTime()}`,
        } 
      )
      .then((res) =>
      {
        loading.value = false
        if (res.data.reference) {
          window.FlutterwaveCheckout({
            public_key: process.env.VUE_APP_FLW_PUBLIC_KEY,
            tx_ref: res.data.reference,
            amount: Number(amount.value),
            currency: 'NGN',
            payment_options: 'card, banktransfer, ussd',
            redirect_url: `${process.env.VUE_APP_FLW_REDIRECT_URL}${company.id}/active/wallet`,
            meta: {
              ledger_account_reference:
                currentWallet.value?.wallet.ledger_account.public_id,
            },
            customer: {
              email: company.email,
              phone_number: company.corporate_phone,
              name: `${company.fname} ${company.lname}`,
            },
            customizations: {
              title: company.corporate_name,
              description: desc.value,
              logo: company.avatar ? company.avatar : '',
            },
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message ?? 'An error occured',
          })
        }
      })
      .catch((err) =>
      {
        loading.value = false
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err?.response?.data?.message ?? 'An error occured',
        })
      })
  }

  return { makePayment, amount, desc, loading }
}

export const useDownloadWallet = () =>
{
  const loading = ref(false)
  const download = (
    walletId,
    type = 'all',
    start_date = null,
    end_date = null,
    userId = '',
  ) =>
  {
    loading.value = true
    axios
      .get(
        `/v1/corporate-wallets/${walletId}/transactions?metadata=true&page=${1}&limit=${data_limit}&filters[type]=${type}${start_date
          ? `&filters[start_date]=${formatApiCallDate(start_date)}`
          : ''
        }${end_date ? `&filters[end_date]=${formatApiCallDate(end_date)}` : ''}${userId ? `&filters[user_ids]=${userId}` : ''}`
      )
      .then((res) =>
      {
        const total = res.data.metadata.total
        axios
          .get(
            `/v1/corporate-wallets/${walletId}/transactions?metadata=true&page=${1}&limit=${total}&filters[type]=${type}${start_date
              ? `&filters[start_date]=${formatApiCallDate(start_date)}`
              : ''
            }${end_date
              ? `&filters[end_date]=${formatApiCallDate(end_date)}`
              : ''
            }${userId ? `&filters[user_ids]=${userId}` : ''}`
          )
          .then((res) =>
          {
            if (res.data.data.length) {
              const x = res.data.data
              const newArr = []
              for (const el of x) {
                const y = {
                  Date:
                    moment(el?.created_at).format('Do MMMM, YYYY h:mm A') ??
                    'N/A',
                  Description: el?.description ?? 'N/A',
                  Type: el?.direction ?? 'N/A',
                  Amount: ` ${el?.amount_formatted}`,
                  Balance_before:
                    el?.available_balance_before_formatted ?? 'N/A',
                  Status: getStatusText(el?.status),
                }
                newArr.push(y)
              }

              const csv = Papa.unparse(newArr)
              const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
              const url = URL.createObjectURL(blob)
              downloadFile(url, 'wallet_transactions')
            } else {
              // useAlert().openAlert('error', 'No data to download', '')
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No data to download',
              })
            }
            loading.value = false
          })
      })
  }
  return { loading, download }
}

const getStatusText = (str) =>
{
  if (str == 'posted') return 'Successful'
  if (str == 'archived') return 'Failed'
  return str ?? 'N/A'
}

export const usePatchCorporateWallet = () =>
{
  const updatedCorporateWallet = ref(null)
  const loading = ref(false)
  const patchCorporateWallet = async (walletId, payload = {}) =>
  {
    if (loading.value) return
    loading.value = true
    return axios
      .patch(`/v1/corporate-wallets/${walletId}`, payload)
      .then((res) =>
      {
        loading.value = false
        if (res.data) {
          updatedCorporateWallet.value = res.data
          return res.data
        }
        throw new Error(res.data)
      })
      .catch((err) =>
      {
        loading.value = false
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text:
            err?.response?.data?.message ??
            err?.data?.message ??
            'An error occured',
        })
      })
  }

  return { loading, patchCorporateWallet, updatedCorporateWallet }
}
